.IconContainer_i13mwm8m{margin-bottom:6px;width:56px;height:56px;}.IconContainer_i13mwm8m > svg{width:100%;}
.Title_t1ysyr05{text-align:center;font-feature-settings:"liga" off,"clig" off;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;font-style:normal;font-weight:600;line-height:22px;-webkit-letter-spacing:-0.49px;-moz-letter-spacing:-0.49px;-ms-letter-spacing:-0.49px;letter-spacing:-0.49px;margin-bottom:0;}
.Text_t1s790mu{text-align:center;font-kerning:none;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.625rem;font-style:normal;font-weight:400;line-height:18px;margin-bottom:0;}
.DetailsLink_d1bucyee{border:0;outline:none;background:none;color:#007aff;text-align:center;font-feature-settings:"liga" off,"clig" off;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.625rem;font-style:normal;font-weight:500;line-height:18px;-webkit-letter-spacing:-0.49px;-moz-letter-spacing:-0.49px;-ms-letter-spacing:-0.49px;letter-spacing:-0.49px;-webkit-text-decoration-line:underline;text-decoration-line:underline;padding:0;cursor:pointer;}
.DisclaimerContainer_dmx3zuc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;gap:8px;}
.DisclaimerStars_d1cmo0nh{width:9px;fill:#FFD268;}
.DisclaimerText_d1x96e8x{margin-bottom:0;text-align:center;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.625rem;font-style:normal;font-weight:600;line-height:16px;-webkit-letter-spacing:-0.069px;-moz-letter-spacing:-0.069px;-ms-letter-spacing:-0.069px;letter-spacing:-0.069px;color:#474952;}
.Container_c1ln43za{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:102px;height:126px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:var(--c1ln43za-0);border-radius:12px;}.Container_c1ln43za:hover{cursor:pointer;}.Container_c1ln43za .Title_t1ysyr05,.Container_c1ln43za .Text_t1s790mu{color:var(--c1ln43za-1);}.Container_c1ln43za .IconContainer_i13mwm8m > svg path{fill:var(--c1ln43za-1);}
.TooltipContainer_t1dbi6hu{width:490px;}
.TooltopBody_tvwvpy8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:24px 24px 12px 24px;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;gap:16px;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;}
.TooltopFooter_t132pjpe{padding:12px 24px 12px 24px;background:#EDEDED;border-radius:0px 0px 12px 12px;margin-bottom:0;color:#0E1A29;width:100%;font-feature-settings:"liga" off,"clig" off;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:14px;font-style:normal;font-weight:500;line-height:20px;-webkit-letter-spacing:-0.069px;-moz-letter-spacing:-0.069px;-ms-letter-spacing:-0.069px;letter-spacing:-0.069px;}.TooltopFooter_t132pjpe > a{color:#1F7DD9;-webkit-text-decoration:underline;text-decoration:underline;}
.TooltipIcon_t1tridi5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:12px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:12px;border-radius:9.6px;background:rgba(244,91,91,0.1);box-shadow:0px 0px 1.2px 0.5px rgba(0,0,0,0.25);}.TooltipIcon_t1tridi5 svg{width:24px;height:24px;}
.TooltipContent_t1w4l3hw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:8px;}
.TooltipTitle_t1d5jdxo{color:#ef7273;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1.125rem;font-style:normal;font-weight:700;line-height:24px;margin-bottom:0;}
.TooltipText_t1uyic25{font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;font-style:normal;font-weight:400;line-height:21px;margin-bottom:0;}.TooltipText_t1uyic25 > span{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:700;}
